.App {
  text-align: center;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

* {
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 1.5px !important;
  /* letter-spacing: 1px !important;
  text-transform: uppercase !important; */
}
 

.main-footer{
   
  bottom: 0%;
  width: 100%;
  position: relative;
  text-align: center;
  
}
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}
.content-wrap {
  flex: 1;
}


.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px; /* Adjust margin as needed */
}

.dot.red {
  background-color: red;
}

.dot.green {
  background-color: green;
}

.editing .dot {
  display: none; /* Hide dot when editing */
}


.css-953pxc-MuiInputBase-root-MuiInput-root::after {
border-bottom: 2px solid black !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
color: black !important;

}
.css-z5e0z9-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  border-bottom: 1px solid black !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: black !important;
}

.custom-row {
  padding: 5px;
  margin: 5px;

}
.MuiDataGrid-cell .MuiButtonBase-root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mui-focused .MuiInputBase-input {
  border-color: black !important;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  border: 1px solid black !important;
}



:where(.css-dev-only-do-not-override-usln0u).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
border: 1px solid black;
}
:where(.css-dev-only-do-not-override-usln0u).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-usln0u).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-usln0u).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner{
  background-color: black;
}


:where(.css-dev-only-do-not-override-usln0u).ant-picker-range .ant-picker-active-bar {
background: black;
}
:where(.css-dev-only-do-not-override-usln0u).ant-picker-dropdown .ant-picker-header-view button:hover {
  color: black;
}


.css-10lvcmn-MuiInputBase-root-MuiInput-root::after{
  border-bottom: 1px solid black !important;
}



.custom-image {
  position: relative;
  padding: 10px ;
  display: inline-block;
}

.custom-image:before,
.custom-image:after {
  content: "";
  position: absolute;
  width: 50%;
  border-color: #708090 !important;
  border-style: solid;
}

.custom-image:before {
  top: 0;
  left: 0;
  border-top-width: 5px;
  border-left-width: 5px;
  border-bottom-width: 0;
  border-right-width: 0;
}

.custom-image:after {
  bottom: 0;
  right: 0;
  border-top-width: 0;
  border-left-width: 0;
  border-bottom-width: 5px;
  border-right-width: 5px;
}



/*  */



.custom-image2 {
  position: relative;
  padding: 20px;
  display: inline-block;
}

.custom-image2:before,
.custom-image2:after {
  content: "";
  position: absolute;
  width: 50%; /* Adjusts the border width to 50% */
  height: 50%; /* Adjusts the border height to 50% */
  border-color: #708090 !important;
  border-style: solid;
}

.custom-image2:before {
  top: 0;
  left: 0;
  border-top-width: 5px; /* Border at the top, extends to the right */
  border-left-width: 5px; /* Border at the left, extends downwards */
  border-bottom-width: 0;
  border-right-width: 0;
}

.custom-image2:after {
  bottom: 0;
  right: 0;
  border-bottom-width: 5px; /* Border at the bottom, extends to the left */
  border-right-width: 5px; /* Border at the right, extends upwards */
  border-top-width: 0;
  border-left-width: 0;
}


/*  */



.custom-image3 {
  position: relative;
  padding: 20px;
  display: inline-block;
}

.custom-image3:before,
.custom-image3:after {
  content: "";
  position: absolute;
  height: 50%;  /* Adjusts the border height to 50% */
  border-color: #708090 !important;
  border-style: solid;
}

.custom-image3:before {
  top: 0;
  left: 0;
  border-top-width: 0;
  border-left-width: 5px;
  border-bottom-width: 0;
  border-right-width: 0;
}

.custom-image3:after {
  bottom: 0;
  right: 0;
  border-top-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-right-width: 5px;
}



.legend1 {
  position: absolute;
  bottom: 5px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  color: #301257;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  
  /* font-size: 12px; */
  text-align: center; /* Aligns text to the left */
  background-color: rgb(211, 206, 218,0.8); /* Assuming you want a background */
  
  /* Transition effects for opacity and overall style */
  transition: opacity .35s ease-in-out, all .5s ease-in-out;
  
  /* Attractive borders on the left and right as described earlier */
  border-color: #301257 !important; /* Change color as needed */
  border-style: solid;
  border-width: 0;
}

.legend1:before,
.legend1:after {
  content: "";
  position: absolute;
  width: 50%; /* Half width of the element */
  height: 50%; /* Half height of the element */
  border-color: #301257; /* Matching the border color */
  border-style: solid;
}

.legend1:before {
  top: 0;
  left: 0;
  border-top-width: 5px; /* Border at the top, extends rightwards */
  border-left-width: 5px; /* Border at the left, extends downwards */
  border-bottom-width: 0;
  border-right-width: 0;
}

.legend1:after {
  bottom: 0;
  right: 0;
  border-bottom-width: 5px; /* Border at the bottom, extends leftwards */
  border-right-width: 5px; /* Border at the right, extends upwards */
  border-top-width: 0;
  border-left-width: 0;
}



.custom-input-focus input {
  color: #708090 !important; /* Ensuring it takes precedence */
}

.custom-input-focus label {
  color: #708090 !important;
}

.custom-input-underline:after {
  border-bottom: 2px solid #708090 !important; /* Style for focused underline */
}


.css-1rrfrxi::after{
  border-bottom: 2px solid #708090 !important;
}


.css-18ifaqw::after{
  border-bottom: 2px solid #708090 !important;
}